import React from 'react'
import './Nav.css'
import {AiOutlineBook, AiOutlineHome, AiOutlineUser} from 'react-icons/ai'
import {RiServiceLine} from 'react-icons/ri'
import {BiMessageSquareDetail} from 'react-icons/bi'
import { useState } from 'react'

import Scrollspy from 'react-scrollspy'

const Nav = () => {

  // const [activeNav,setActiveNav]=useState('#')
  // const scrollPosition = scrollPosition();


  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };


  return (
    
    <nav >

      
      
      <Scrollspy
        items={ ['home', 'about', 'experience','portfolio', 'contact'] }
        currentClassName="active" className='nav__bar' >

    {/* <a href="#about">about</a>
    <a href="#contact">contact</a>
    <a href="#section-3">section 3</a> */}


      <a href='#' className='dock'/*onClick={()=>setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}*/><AiOutlineHome/></a>
      <a href='#about' className='dock'/*onClick={()=>setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}*/><AiOutlineUser/></a>
      <a href='#experience' className='dock'/*onClick={()=>setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}*/><AiOutlineBook/></a>
      <a href='#portfolio' className='dock'/*onClick={()=>setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}*/><RiServiceLine/></a>
      <a href='#contact' className='dock'/*onClick={()=>setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}*/><BiMessageSquareDetail/></a>
      
      </Scrollspy>
      

        

    </nav>
  )
}

export default Nav

