import React from 'react'
import CTA from './CTA'
import'./Header.css'
import Me from '../../assets/Me.png'
import Logo from '../../assets/logo.png'
import HeaderSocials from './HeaderSocials'
 
const Header = () => {
  return (
    <header id='home'>
      <div className='logo'>

        <img src={Logo} alt='Achiyant'>
        </img>
        </div>
      <div className='container header__container'>
        
        
        <h5> Hello I'm</h5>
        <h1 style={{ cursor: 'url(/Users/achiyant/Documents/my-portfolio/src/assets/mglass.png),auto' }} id="name">Achiyant</h1>
        <h5 className='text-light'>Software Developer</h5>
        <CTA/>
        <HeaderSocials/>
        <div className='me'>
          <img src={Me} alt="me"></img>
        </div>
        <a id='scroll' href='#footer' className='scroll__down'>Scroll Down</a>
        
      </div>
    </header>
  )
}

export default Header