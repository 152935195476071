import React from 'react'
import './Portfolio.css'
import portfolio1 from '../../assets/portfolio1.png.jpg'
import portfolio2 from '../../assets/portfolio2.png'
import portfolio3 from '../../assets/portfolio3.jpg'
const data=[
  {
    id:1,
    image: portfolio1,
    title: 'Vehicle Speed Detection & E-Challan Generation (Undergoing)',
    github: 'https://github.com/medical-test-recommender-based-on-symptoms',
    // demo: null
  },
  {
    id:2,
    image: portfolio2,
    title: 'Medical Test Recommender Based On Symptoms',
    github: 'https://github.com/medical-test-recommender-based-on-symptoms',
    demo: 'https://symptomscheck.herokuapp.com'
  },
  {
    id:3,
    image: portfolio3,
    title: 'Face Recogniton Attendance System',
    github: 'https://github.com/ACHIYANT/Face-Recognition-Attendance-System',
    // demo: 'https://symptomscheck.herokuapp.com'
  },
  // {
  //   id:4,
  //   image: portfolio1,
  //   title: 'Car Polling System',
  //   gtihub: 'https://github.com/medical-test-recommender-based-on-symptoms',
  //   demo: 'https://symptomscheck.herokuapp.com'
  // },
]
const Portfolio = () => {
  return (
    

    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
      {
        data.map(({id, image, title, github, demo})=>{
          return(
          <article key={id} className='portfolio__item'>
            <div className='portfolio__item-image'>
              <img src={image} alt={title}></img>
            </div>
            <h3>{title}</h3>
            <div className='portfolio__item-cta'>
              <a href={github} className='btn' target='_blank'>Github</a>
              {demo && (
                <a href={demo} className='btn btn-primary' target="_blank" rel="noreferrer">
                  {/* <Icon name="GitHub" /> */}
                  Live Demo
                </a>
              )}
              {/* <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a> */}
            </div>
          </article>
          )
        })
      }
        {/* <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={portfolio1}></img>
          </div>
          <h3>This is a portfolio item title</h3>
          <div className='portfolio__item-cta'>
            <a href='https://github.com' className='btn' target='_blank'>Github</a>
            <a href='#' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article> */}
{/* 

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={portfolio1}></img>
          </div>
          <h3>This is a portfolio item title</h3>
          <div className='portfolio__item-cta'>
            <a href='https://github.com' className='btn' target='_blank'>Github</a>
            <a href='#' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>


        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={portfolio1}></img>
          </div>
          <h3>This is a portfolio item title</h3>
          <div className='portfolio__item-cta'>
            <a href='https://github.com' className='btn' target='_blank'>Github</a>
            <a href='#' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>



        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={portfolio1}></img>
          </div>
          <h3>This is a portfolio item title</h3>
          <div className='portfolio__item-cta'>
            <a href='https://github.com' className='btn' target='_blank'>Github</a>
            <a href='#' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>



        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={portfolio1}></img>
          </div>
          <h3>This is a portfolio item title</h3>
          <div className='portfolio__item-cta'>
            <a href='https://github.com' className='btn' target='_blank'>Github</a>
            <a href='#' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>



        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={portfolio1}></img>
          </div>
          <h3>This is a portfolio item title</h3>
          <div className='portfolio__item-cta'>
            <a href='https://github.com' className='btn' target='_blank'>Github</a>
            <a href='#' className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article> */}

      </div>
    </section>
  )
}

export default Portfolio