import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub, FaInstagram, FaTwitter} from 'react-icons/fa'
import { FaWhatsapp } from 'react-icons/fa'
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://www.linkedin.com/in/achiyant/' target='_blank' ><BsLinkedin/></a>
        <a href='https://github.com/achiyant' target='_blank'><FaGithub/></a>
        <a href='https://wa.me/+919588759425' target='_blank'><FaWhatsapp/></a>
        <a href='https://instagram.com/thisisachiyant' target='_blank'><FaInstagram/></a>
        <a className='twitter' id='twit' href='https://twitter.com/achiyant' target='_blank'><FaTwitter/></a>
        
    </div>
  )
}

export default HeaderSocials