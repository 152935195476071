import React from 'react'
import './Contact.css'
import {MdOutlineEmail,} from 'react-icons/md'
import { AiOutlineWhatsApp, AiOutlineInstagram, AiOutlinePhone } from 'react-icons/ai'
import { useRef } from 'react';
import emailjs from 'emailjs-com'
// import call from 'react-native-phone-call'
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_b6mfbdl', 'template_n7j1m8d', form.current, 'F-2ELUiVMwvxUNf4H')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'></MdOutlineEmail>
            <h4 className=''>Email</h4>
            <h5>achiyant@gmail.com</h5>
            <a href='mailto:achiyant@gmail.com'>Send a message</a>
          </article>
          <article className='contact__option'>
            <AiOutlineInstagram className='contact__option-icon'></AiOutlineInstagram>
            <h4 className=''>instagram</h4>
            <h5>@thisisachiyant</h5>
            <a href='https://instagram.com/thisisachiyant' target='_blank' rel='noreferrer'>Send a message</a>
          </article>
          <article className='contact__option'>
            <AiOutlineWhatsApp className='contact__option-icon'></AiOutlineWhatsApp>
            <h4 className=''>Whatsapp</h4>
            <h5>+91-9588759425</h5>
            <a href='https://wa.me/+919588759425' target='_blank' rel='noreferrer'>Send a message</a>
          </article>
          <article className='contact__option'>
            <AiOutlinePhone className='contact__option-icon'></AiOutlinePhone>
            <h4 className=''>Call</h4>
            <h5>+91-9588759425</h5>
            <a href='tel:+919588759425' target='_blank' rel='noreferrer'>Make a call</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Your Full Name' required/>
          <input type='email' name='email' placeholder='Your Email' required/>
          <textarea name='message' rows='7' placeholder='Your Message' required/>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact