import React from 'react'
import './About.css'
import ME from '../../assets/me-about.jpg'
import { FaAward } from 'react-icons/fa'
const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        

        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>C/C++</h5>
              <small>2+ Years Working</small>
            </article>

            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>React</h5>
              <small>1 Year Working</small>
            </article>

            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Java</h5>
              <small>1+ Year Working</small>
            </article>
          </div> 
          <p>
            Hello! My name is Achiyant and I enjoy creating things that live on the internet. My
            interest in web development started back in 2020 when I decided to try some custom
            designed pages — turns out creating together a custom webpage taught me a lot
            about HTML &amp; CSS!
          </p>

          <p>
            Fast-forward to today, and I’ve had the privilege of working at{' '}
            {/* <a href="https://us.mullenlowe.com/">an advertising agency</a>,{' '}
            <a href="https://starry.com/">a start-up</a>,{' '}
            <a href="https://www.apple.com/">a huge corporation</a>, and{' '}
            <a href="https://scout.camd.northeastern.edu/">a student-led design studio</a>.  */}
            My
            main focus these days is learning new technologies, solving problem.
          </p>

          <p>
            I also recently{' '}
            {/* <a href="localhost:3000"> */}
            made this portfolio website
            {/* </a>{' '} */}
            that helps me in learning about custom hooks and many more things in React.Js
          </p>

          <p>Here are a few technologies I’ve been working with recently:</p>
          <ul className="skills-list" >
            <li>React JS</li>
            <li>CSS</li>
            <li>C++</li>
            <li>Machine Learning</li>
            {/* {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)} */}
          </ul>
        </div>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt='About Image'></img>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About