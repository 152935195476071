import React from 'react'
import './Footer.css'
import { FaWhatsapp, FaLinkedinIn, FaInstagram } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer id='footer'>
      <a href='#' className='footer__logo'>Achiyant</a>

      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        {/* <li><a href='#services'>Services</a></li> */}
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#contact'>Contact</a></li>

      </ul>
      <div className='footer__socials'>
        <li><a id='wap' href='https://wa.me/+919588759425' target='_blank' rel='noreferrer'>
          <FaWhatsapp className='footer__socials-icons'/>
        </a></li>
        <li>
          <a href='https://instagram.com/thisisachiyant' target='_blank' rel='noreferrer'>
          <FaInstagram className='footer__socials-icons'/>
        </a>
        </li>
        <li>
          <a href='https://www.linkedin.com/in/achiyant/' target='_blank' rel='noreferrer'>
          <FaLinkedinIn className='footer__socials-icons'/>
        </a>
        </li>
      </div>
      <div className='footer__copyright'>
        <small>Copyright &copy; 2022 Achiyant. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer